<template>
  <div class="base-container">
    <div class="page-body">
      <div class="page-img">
        <img src="@/assets/ml-logo.png" />
      </div>
      欢迎使用过磅系统！
    </div>
  </div>
</template>
<script>
export default {
  name: 'dashboard',
  data() {
    return {
      // src: '//datav-bt.wechain360.com/'
    }
  },
}
</script>

<style lang="scss" scoped>
.base-container {
  .page-body {
    margin: 100px auto 0 auto;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #555;
  }
  .page-img {
    margin-bottom: 30px;
  }
}
</style>
